<template>
  <div class="container">
    <div class="pretension-page">
      <section class="header">
        <p class="text-center font-weight-700 font-size-28">ООО «ARHAT GRAVURE»</p>

        <div class="h-line mb-10"></div>

        <p class="text-center">
          г. Ташкент, Юнусабадский район, улица Каракамышская, дом 2 , р.с.: 2020 8000 5052 9012 4001, в ЦОО АКБ
          «Капиталбанк», МФО: 01088, ИНН: 307 769 761, ОКЭД: 32990
        </p>

        <div class="d-flex justify-content-between mb-10 font-weight-700">
          <span>Дата {{ order.contract.created_at }}</span>
          <span>№ _____</span>
        </div>

        <div class="text-right">
          <span style="font-style: italic">гр-ну (ке):</span>
          {{ buyerFIO }}
          <br>
          <span>Адрес прописки: {{ order.address || '____________' }}</span>
        </div>
      </section>

      <section class="main">
        <h3 class="text-center">П Р Е Т Е Н З И Я</h3>

        <p>
          <span class="space">___</span> Уважаемый(-ая) {{ buyerFIO }} <!--настоящим--> сообщаем Вам о наличии за Вами
          задолженности,
          возникшей по Договору №{{ order.contract.id }} от {{ order.contract.created_at }} года, заключенного с
          компанией ООО «ARHAT GRAVURE».
        </p>
        <p>
          <span class="space">___</span> Несмотря на факт исполнения нашим предприятием обязательств в сроки и в
          соответствии с требованиями,
          предусмотренными в Оферте и Договоре №{{ order.contract.id }} от {{ order.contract.created_at }} года, до
          сегодняшнего дня с Вашей стороны не была
          произведена оплата поставленного товара.
        </p>
        <p>
          <span class="space">___</span> В результате несвоевременного выполнения своих обязательств, с Вашей стороны
          имеется задолженность, которая на
          <span
            class="mr-4"
            v-for="(debt, index) in order.contract.debts"
            :key="index"
          >
            {{ this.$moment(debt.created_at).format('DD.MM.YYYY') }}
            {{ order.contract.debts.length !== index + 1 ? ',' : '' }}
          </span>
          составляет {{ order.totalDebt }} сум,
          в связи с чем, согласно ст.242 Гражданского кодекса Республики
          Узбекистан, мы настоятельно просим произвести оплату в трёхдневный срок.
        </p>
        <p>
          <span class="space">___</span> В случае неисполнения Вами своих обязательств, мы будем вынуждены обратиться в
          нотариальную контору или в суд
          для принудительного взыскании основного долга в размере {{ order.totalDebt }} сум, что в свою очередь может
          послужить
          увеличению задолженности на сумму госпошлины, почтовых расходов, пени, понесенных убытков (в том числе
          упущенной
          выгоды) с Вас либо за счёт Вашего имущества.
        </p>
        <p>
          <span class="space">___</span> Вы можете оплатить задолженность путем пополнения своего ID (№ID) платежными
          системами CLICK, PAYME, APELSIN или
          по следующим реквизитам в любом отделении Банка:
        </p>
      </section>

      <section class="footer">
        <p><span class="font-weight-700">Компания: </span> <span>ООО «ARHAT GRAVURE»</span></p>
        <p><span class="font-weight-700">Р/с: </span> <span>2020 8000 5052 9012 4001 в ЦОО АКБ «Капиталбанк»</span></p>
        <p>
          <span class="font-weight-700">МФО: </span> <span class="mr-8">01088;</span>
          <span class="font-weight-700">ИНН: </span> <span class="mr-8">307 769 761;</span>
          <span class="font-weight-700">ОКЭД: </span> <span>32990</span>
        </p>
        <p class="font-weight-700">
          При оплате в отделении Банка обязательно укажите ФИО и ваш ID (номер сотового телефона).
        </p>
        <div class="font-weight-700">
          <br>
          <span>С Уважением,</span>
          <br><br>
          <span>Директор</span>
          <div class="d-flex justify-content-between">
            <span>ООО «ARHAT GRAVURE»</span> <span>Тожиев П.М.</span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie';

export default {
  name: 'PretensionPage',
  props: ['contractId'],
  data: () => ({
    order: null,
  }),
  computed: {
    buyerFIO() {
      if (this.order === null) return '_____';

      return `${this.order.buyer.surname} ${this.order.buyer.name} ${this.order.buyer.patronymic}`;
    },
  },
  methods: {
    async getOrders() {
      try {
        const { data: response } = await this.$axios.post('orders/list', {
          api_token: Cookie.get('token'),
          params: [
            {
              'contract|id': this.contractId,
              'contract|status': 4,
            },
            {
              'contract|id': this.contractId,
              'contract|status': 3,
              query_operation: 'or',
            },
          ],
        });
        if (response.status === 'success') {
          this.order = response.data[0];
        } else {
          console.log('error');
        }

      } catch (e) {
        this.$toastError(e.message);
      }
    },
  },
  created() {
    this.getOrders();
    document.title = '';
    setTimeout(() => {
      document.querySelector('.b24-widget-button-wrapper').style.display = 'none';
    }, 2000)
  },
};
</script>

<style lang="scss" scoped>
  .pretension-page {
    .b24-widget-button-wrapper {
      display: none;
    }

    padding-top: 80px;
    padding-bottom: 150px;

    p {
      line-height: 24px;
    }

    .space {
      opacity: 0;
    }

    .header {
      p {
        margin: 0 0 20px 0;
      }

      .h-line {
        width: 100%;
        height: 4px;
        background-color: $black;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: -3px;
          height: 1px;
          width: 100%;
          background-color: $black;
        }

        &:before {
          content: '';
          position: absolute;
          top: 6px;
          height: 1px;
          width: 100%;
          background-color: $black;
        }
      }
    }

    .main {
      p {
        &:last-child {
          margin: 10px 0 0 0;
        }
      }

      h3 {
        margin: 20px 0;
      }
    }

    .footer {
      margin: 10px 0 0 0;
    }
  }
</style>